// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const QUERY = gql`
  query DossierTasksQuickAddFormByBlockId(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $blockId: ID!
  ) {
    quickAddForm: dossierTasksQuickAddFormByBlockId(
      dossierId: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      blockId: $blockId
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

const MUTATION = gql`
  mutation DossierTasksQuickAddFormByBlockId(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $blockId: ID!,
    $data: JSON
  ) {
    quickAddForm: dossierTasksQuickAddFormByBlockId(
      dossierId: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      blockId: $blockId,
      data: $data
    ) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

export { QUERY, MUTATION };
